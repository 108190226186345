import axios from 'axios'
import CONSTANTS from '@/constants/CONSTANTS'

// https://freesound.org/docs/api/resources_apiv2.html#text-search
const FREESOUNDS_BASE_URL = 'https://freesound.org/apiv2'
const FREESOUNDS_TOKEN = '00wVZ19EbJAkFBgzMNMlQa0N8uC6MlK9Hf9tqHa9'

const SPEXT_BASEURL = CONSTANTS.BASE_LINK

export default {

  /**
   * Get sounds matching the search criteria
   */
  getSounds (query) {
    let newUrl = new URL(`${FREESOUNDS_BASE_URL}/search/text/`)
    newUrl.searchParams.set('token', FREESOUNDS_TOKEN)
    newUrl.searchParams.set('fields', 'id,name,duration,previews')
    newUrl.searchParams.set('query', query)
    newUrl.searchParams.set('page', 1)
    newUrl.searchParams.set('page_size', 150)
    newUrl.searchParams.set('sort', 'rating_desc')
    newUrl.searchParams.append('filter', 'license:"Creative Commons 0"')
    newUrl.searchParams.append('filter', 'type:(wav OR mp3 OR flac)')
    newUrl.searchParams.append('filter', 'duration:{* TO 50}')
    // console.log(newUrl.href)
    return axios.get(newUrl.href)
  },

  /**
   * Get details for a particular sound (by ID)
   */
  getSound (soundId) {
    return axios.get(`${FREESOUNDS_BASE_URL}/sounds/${soundId}/?token=${FREESOUNDS_TOKEN}`)
  },

  /**
   * Get similar sounds for a particular sounds (by ID)
   */
  getSimilarSounds (soundId) {
    let newUrl = new URL(`${FREESOUNDS_BASE_URL}/sounds/${soundId}/similar/`)
    newUrl.searchParams.set('token', FREESOUNDS_TOKEN)
    newUrl.searchParams.set('fields', 'id,name,duration,previews')
    newUrl.searchParams.set('page', 1)
    newUrl.searchParams.set('page_size', 150)
    newUrl.searchParams.set('sort', 'rating_desc')
    newUrl.searchParams.append('filter', 'license:"Creative Commons 0"')
    newUrl.searchParams.append('filter', 'type:(wav OR mp3 OR flac)')
    newUrl.searchParams.append('filter', 'duration:{* TO 50}')
    // console.log(newUrl.href)
    return axios.get(newUrl.href)
  },

  /**
   * Upload sounds
   * @params body {sound_id, userid, name, url(will be used when called from generate sound)}
   * @return music id (m-xyz) || -1 when error
   */
  uploadSound (body) {
    return axios.post(`${SPEXT_BASEURL}/upload_sound`, body)
  },

  /**
   * Generate sound for the text typed by user
   * @params body {userid, text (text user has typed), id (voice id from list of voices) }
   * @return audio URL to play the audio || -1 when error
   */
  generateSound (body) {
    return axios.post(`${SPEXT_BASEURL}/generate_sound`, body)
  }
}
