<style lang="scss">
.syntheticvoices {
  min-height: 312px;
  max-height: 312px;

  .inputContainer {
    height: 210px;
  }

  textarea {
    resize: none;
    height: 171px;
    width: 100%;
  }

  .complete-section {
    min-height: 300px;
    max-height: 300px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  .in-progress-section {
    min-height: 300px;
    max-height: 300px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    .progress-outer {
      width: 430px;
      background-color: #F3F4F6;
      height: 10px;
      border-radius: 10px;
      margin: 0 auto;
    }
    .progress-inner {
      width: 10%;
      background-color: #E2462C;
      height: 10px;
      border-radius: 10px;
      transition: 0.2s ease;
    }
  }

  .name-section {
    min-height: 300px;
    max-height: 300px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    position: relative;
  }
}

.voiceSelect {
  width: 410px;
  border-radius: 4px;
  margin: 0 16px;

  .dropdown-menu {
    width: 100%;
    background: white;
    max-height: 140px;
    overflow-y: auto;
    border-radius: 4px;
    border: none;
  }

  .dropdown-toggle {
    color: unset;
    font-weight: unset;
    font-size: 14px;
    line-height: 20px;
    background-image: none;
    margin: 0;
    padding: 10px 16px;
    border-radius: 4px;

    &:hover {
      background: none;
    }
  }
}
</style>

<template>
  <div class="w-full p-4 syntheticvoices">
    <!-- Upload landing -->
    <div v-if="uploadStatus === 'enter'">
      <div class="border rounded border-primary-300 inputContainer relative">
        <textarea
          v-model="text"
          @keyup="updateText()"
          maxlength="10000"
          placeholder="Type here"
          class="border-none text-primary p-4 placeholder-primary-400 focus:ring-0"
        />

        <div class="border-t border-primary-300 text-sm text-right w-full py-2 px-2.5 bottom-0 text-primary-500 absolute">
          <span>characters remaining <b>{{charactersRemaining}}</b></span>
        </div>
      </div>

      <div class="flex mt-6 items-center">
        <div class="text-sm text-primary">Voice Name</div>
        <Dropdown
          class="bg-primary-100 voiceSelect"
          :options="voices"
          :selected="selectedVoice"
          v-on:updateOption="selectVoice"
          :placeholder="'Select a voice'"
        />

        <div
          v-if="!status || status==='pause'"
          @click="startGenerateAudio"
          class="text-primary"
          :class="isDisabled ? '' : 'cursor-pointer'"
        >
          <VoiceOver class="h-6 w-6"/>
        </div>
        <div
          v-else-if="status==='seeking' || status==='playing'"
          @click="setPause"
          class="text-green"
        >
          <VoiceOver class="h-6 w-6"/>
        </div>
      </div>

    </div>

    <!-- File name section -->
    <div v-else-if="uploadStatus === 'uploadFile'" class="name-section">
      <span class="flex text-primary top-0 left-0 absolute items-center" @click="backBtn()">
        <ArrowBack />
        <div class="text-sm ml-2">Back</div>
      </span>

      <input
        type="text"
        v-model="fileTitle"
        @keyup="updateFileTitle()"
        placeholder="Title"
        class="border-none rounded-tr bg-primary-100 text-sm text-primary py-3 pr-10 pl-3 placeholder-primary-500 w-9/12 block focus:ring-0"
      />
    </div>

    <!-- Progress Bar -->
    <div v-else-if="uploadStatus === 'inProgress'" class="in-progress-section">
      <div class="text-sm mb-4">
        Adding the file...
      </div>

      <div class="progress-outer">
        <div
          class="progress-inner"
          :style="'width:'+addFileProgressBarPercent+'%;'"
          ref="addFileProgressBar"
        />
      </div>

      <span class="font-semibold mt-6 text-sm">
        {{addFileProgressBarPercent}} %
      </span>
    </div>

    <!-- Upload completed -->
    <div v-else-if="uploadStatus === 'complete'" class="complete-section">
      <div class="text-green">
        <FileAdded class="h-10 w-10" />
      </div>
      <div v-if="fileTitle" class="mt-3 text-sm text-primary">
        {{ fileTitle }}
      </div>
    </div>

    <AddFilesFooter :config="footerConfig" @actionBtnClick="actionBtnClick($event)"></AddFilesFooter>

    <audio @playing="playing" @pause="pause" @ended="ended" @seeking="seeking" @waiting="waiting" ref="audio"/>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import Dropdown from 'vue-dropdowns'
import SoundsService from '@/services/api/Sounds'
import store from '@/services/store'
import { getURLfromPath } from '../../utility'

import FileAdded from '@/components/base/icons/FileAdded.vue'
import VoiceOver from '@/components/base/icons/VoiceIcon.vue'
import ArrowBack from '@/components/base/icons/ArrowBack.vue'

export default {
  name: 'SyntheticVoices',
  components: {
    Dropdown,
    VoiceOver,
    FileAdded,
    ArrowBack,
    AddFilesFooter: () => import('../../AddFileFooter/AddFileFooter')
  },
  computed: {
    ...mapGetters({
      modalProps: 'dialogs/modalProps'
    }),
    isReplacing: function() {
      return this.modalProps.isReplacing
    },
    user () {
      return store.state.app.user
    },
    charactersRemaining () {
      return 10000 - this.text.length
    }
  },
  data () {
    return {
      voices: [
        {
          name: 'John (US)',
          value: 'john'
        },
        {
          name: 'David (US)',
          value: 'david'
        },
        {
          name: 'Kim (US)',
          value: 'kim'
        },
        {
          name: 'Jack (US)',
          value: 'jack'
        },
        {
          name: 'Kylie (US)',
          value: 'kylie'
        },
        {
          name: 'Jane (US)',
          value: 'jane'
        },
        {
          name: 'Catherine (British)',
          value: 'catherine'
        },
        {
          name: 'William (British)',
          value: 'william'
        },
        {
          name: 'Elizabeth (British)',
          value: 'elizabeth'
        },
        {
          name: 'Phillip (British)',
          value: 'phillip'
        },
        {
          name: 'Madhuri (India)',
          value: 'madhuri'
        },
        {
          name: 'Karan (India)',
          value: 'karan'
        },
        {
          name: 'Deepak (India)',
          value: 'deepak'
        },
        {
          name: 'Anna (Australia)',
          value: 'anna'
        },
        {
          name: 'Darren (Australia)',
          value: 'darren'
        },
        {
          name: 'Nicole (Australia)',
          value: 'nicole'
        },
        {
          name: 'Chris (Australia)',
          value: 'chris'
        }
      ],
      isDisabled: true,
      text: '',
      fileTitle: '',
      selectedVoice: '',
      uploadStatus: '',
      audioURL: '',
      audio: {},
      togglePlayLoader: false,
      addFileProgressBarPercent: 0,
      footerConfig: {
        message: 'All sounds are from Freesound.org and you can use them commercially for your projects. More details ',
        showHereLink: true,
        actionBtns: {
          'nextBtn': {
            label: 'Next',
            type: 'nextBtn',
            isDisabled: true,
            isHidden: false
          },
          'uploadBtn': {
            label: 'Add to Library',
            type: 'uploadBtn',
            isDisabled: true,
            isHidden: true
          },
          'addFileBtn': {
            label: 'Add file',
            type: 'addFileBtn',
            isDisabled: true,
            isHidden: true
          }
        }
      },
      selectVoiceMenuConfig: {
        show: false,
        showFullScreen: false,
        transition: 'bounce-center',
        style: {},
        closeOnClickOutside: true,
        backdrop: false
      },
      audioPath: null,
      status: ''
    }
  },
  mounted () {
    this.selectedVoice = this.voices[0]
    this.uploadStatus = 'enter'
  },
  methods: {
    updateText () {
      if (this.text.length) {
        this.isDisabled = false
        this.footerConfig.actionBtns['nextBtn'].isDisabled = false
      } else {
        this.isDisabled = true
        this.footerConfig.actionBtns['nextBtn'].isDisabled = true
      }
      this.audioURL = ''
    },
    startGenerateAudio () {
      let vm = this
      console.log('startGenerateAudio')
      if (!this.audioURL && this.status !== 'seeking') {
        this.status = 'seeking'
        this.generateAudio().then(
          (res) => {
            console.log(res)
            vm.audioPath = res.data
            getURLfromPath(res.data).then(function (url) {
              console.log(url)
              vm.audioURL = url
              vm.playAudio(vm.audioURL)
            })
          }
        )
      } else {
        this.playAudio(this.audioURL)
      }
    },
    playAudio (url) {
      let vm = this
      vm.$refs.audio.src = url
      vm.$refs.audio.play()
    },
    actionBtnClick (actionBtn) {
      if (actionBtn.type === 'nextBtn') {
        this.uploadStatus = 'uploadFile'
        this.footerConfig.actionBtns['nextBtn'].isHidden = true
        this.footerConfig.actionBtns['uploadBtn'].isHidden = false
      } else if (actionBtn.type === 'uploadBtn') {
        this.uploadStatus = 'inProgress'
        this.footerConfig.actionBtns['addFileBtn'].isDisabled = true
        if (!this.audioURL) {
          this.generateAudio().then(
            (res) => {
              console.log('res', res)
              this.audioPath = res.data
              this.uploadFile()
            }
          )
        } else {
          this.uploadFile()
        }
      } else if (actionBtn.type === 'addFileBtn') {
        store.dispatch('modal/addFile', this.isReplacing)
      }
    },
    backBtn () {
      this.uploadStatus = 'enter'
      this.footerConfig.actionBtns['nextBtn'].isHidden = false
      this.footerConfig.actionBtns['addFile'].isHidden = true
    },
    updateFileTitle () {
      if (this.fileTitle.length) {
        this.isDisabled = false
        this.footerConfig.actionBtns['uploadBtn'].isDisabled = false
      } else {
        this.isDisabled = true
        this.footerConfig.actionBtns['uploadBtn'].isDisabled = true
      }
    },
    uploadFile () {
      const body = {
        userid: this.user.uid,
        name: this.fileTitle,
        url: this.audioPath
      }

      SoundsService.uploadSound(body).then(
        (res) => {
          console.log(res)
          this.uploadStatus = 'complete'
          this.footerConfig.actionBtns['uploadBtn'].isHidden = true
          this.footerConfig.actionBtns['addFileBtn'].isHidden = false
          this.footerConfig.actionBtns['addFileBtn'].isDisabled = false
          store.commit('modal/setSelectedFile', { type: 'synthetic_audio', key: res.data })
        },
        (err) => {
          console.log(err)
        }
      )
    },
    selectVoice (item) {
      this.selectedVoice = item
      this.closeSelectVoiceMenu()
      this.audioURL = ''
    },
    closeSelectVoiceMenu () {
      this.selectVoiceMenuConfig.show = false
    },
    generateAudio () {
      const body = {
        userid: this.user.uid,
        text: this.text,
        id: this.selectedVoice.value
      }
      console.log(body)
      return SoundsService.generateSound(body)
    },
    setPause () {
      console.log('pausing')
      this.$refs.audio.pause()
    },
    playing () {
      console.log('playing')
      this.status = 'playing'
    },
    pause () {
      console.log('paused')
      this.status = 'pause'
    },
    ended () {
      console.log('ended')
      this.status = ''
    },
    seeking () {
      console.log('seeking')
      this.status = 'seeking'
    },
    waiting () {
      console.log('wating')
      this.status = 'seeking'
    }
  }
}
</script>
